@import "../variables";
.register-page {
    .content {
        max-width: 870px;
    }
    .head-note {
        max-width: 42rem;
    }
    .content-note {
        p {
            margin-bottom: 0.2rem;
        }
    }
    .form-group {
        .input-group {
            .label {
                font-weight: bold;
            }
            .error {
                color: $red;
                font-size: .8rem;
                margin-top: .2rem;
                margin-bottom: 0;
            }
            .text-note {
                color: $red;
                font-size: .9rem;
                font-weight: bold;
            }
        }
    }
    .btn-super-lg {
        padding-left: calc((340px - 203px) / 2);
        padding-right: calc((340px - 203px) / 2);
        min-width: 13rem;
        line-height: 2;
        padding-top: .6rem;
        padding-bottom: .6rem;
    }
    &.confirm-page {
        .content-note {
            margin-bottom: .4rem;
        }
    }

    .term {
        .term-head, .term-list {
            font-size: 1rem !important;
        }

        .term-list {
            margin-top: 1rem !important;
            margin-bottom: 0 !important;
        }
    }

    .term-box {
        border:1px solid #BEBEBE;
        height:150px; 
        overflow:scroll; 
        padding:20px; 
        background-color:#F4F4F4;
        color: #707070;
        max-width: 700px;
        margin: 0 auto;
      }
}

.register-extra-page {
    .head-note {
        width: 70.5rem;
        font-size: 1.5rem;
    }

    .head-group {
        background-color: $gray-light;
        padding: .5rem 1.5rem;
        font-weight: bold;
        font-size: 1.5rem;
    }
    .form-register-extra {
        margin: 0 auto 0 auto;
        max-width: 83.625rem;
    }
    .form-group {
        label {
            font-weight: bold;
            font-size: 1.125rem;
            &.comment-title {
                font-size: 1.125rem;
            }
        }
        .input-group {
            .form-control {
                &[type="text"],
                &.select-dexpo.select-default {
                    height: 3rem;
                    border-radius: 5px;
                }
            }
            .label {
                .mt-md-n5 {
                    margin-bottom: -1.5rem !important;
                }
                .badge {
                    font-size: 1.125rem;
                }
            }
            .text-append {
                font-size: 1.125rem;
            }
            .comment-text {
                max-width: 100%;
            }
        }
    }
    .option-group {
        .form-check-inline {
            margin-right: 0;
        }
        label {
            font-weight: bold;
            &.radio-dexpo {
                font-size: 1.125rem;
            }
        }

        div[class^="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .term {
        .term-head {
            font-size: 1.125rem
        }
        .frame-term {
            border: 1px solid $black-light;
            padding: 0 1rem;
            height: 200px;
            overflow-y: auto;
            width: 100%;
            margin-top: 1rem;
            font-size: 1.125rem;
            .inside-text {
                padding: 1rem 0;
                max-width: 600px;
                margin: 0 auto;
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .term-agree {
            font-size: 1.125rem;
        }

        .term-detail {
            border: 1px solid #BEBEBE;
            border-radius: 5px;
            color: $gray-dark;
            padding: 10px;
            height: 15rem;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
  .term-list {
    margin-top: 2rem;
    font-size: 1.125rem;
    color: $key-color;
      padding-left: 0;

    a {
      font-size: inherit;
      color: inherit;
    }
  }
    .btn-super-lg {
        font-size: 1.125rem;
        padding-left: calc((340px - 203px) / 2);
        padding-right: calc((340px - 203px) / 2);
        min-width: 13rem;
        line-height: 1.7;
    }
    .interest-help {
        font-size: .8rem;
        margin-left: auto;
    }
}

.confirm-extra-page {
    form {
        .form-group {
            margin-bottom: 0;
            &:nth-child(odd) {
                background-color: $gray-light;
            }
        }
        .input-group {
            padding: .6rem .5rem;
            label {
                margin-bottom: 0;
            }
        }
        .btn-register {
            min-width: 17rem;
        }
        .btn-edit {
            min-width: 17rem;
            margin-top: 1rem;
            background-color: #E4E4E4;
            color: #707070;
        }
    }
}

.complete-page {
    .content-note {
        font-weight: normal;
        max-width: 670px;
        margin: 0 auto;
        .datetime {
            strong {
                letter-spacing: 1px;
                font-size: 1.125em;
                margin: 0 .7rem;
            }
        }
    }
    .btn-login {
        line-height: 2;
    }
    .btn-logout {
        line-height: 2;
        min-width: 20rem;
    }
    .btn-back-to-top {
        min-width: 20rem;
    }
}

@media screen and (max-width: 1600px) {
    .register-extra-page {
        .head-note {
            width: fit-content;
        }
        .form-register-extra {
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 1024px) {
    .register-page {
        .head-note {

        }
    }
}

@media screen and (max-width: 768px) {
    .register-extra-page {
        .form-group {
            label {
                font-size: 1rem;
                &.comment-title {
                    font-size: 0.8rem;
                }
            }
            .input-group {
                .form-control {
                    &[type="text"],
                    &.select-dexpo.select-default {
                        height: 3.5rem;
                    }
                }
                .label {
                    .mt-md-n5 {
                        margin-bottom: -1.5rem !important;
                    }
                }
                .text-append {
                    font-size: 0.8rem;
                }
            }
        }
        .badge {
            white-space: nowrap;
            line-height: 1.5;
            font-size: .8rem;
            br {
                display: none;
            }
        }
        .option-group {
            label {
                &.radio-dexpo {
                    font-size: 1rem;
                }
            }
        }
        .head-group {
            font-size: 1rem;
        }
        .term {
            .term-head {
                font-size: 1rem
            }
            .frame-term {
                font-size: 0.8rem;
            }
            .term-agree {
                font-size: 1rem;
            }
        }
        .term-list {
            font-size: 1rem;
        }
        .btn-super-lg {
            font-size: 1rem;
            padding-left: calc((224px - 126px) / 2);
            padding-right: calc((224px - 126px) / 2);
        }
        // todo
        .pc-desktop {
            display: block;
        }
        .sm-mobile {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .register-page {
        .head-note {
            padding-left: 15px;
            padding-right: 15px;
            font-weight: normal !important;
            br {
                &:last-child {
                    display: none;
                }
            }
        }
        .form-group {
            .badge-required {
                line-height: 1.5;
                span {
                    display: inline;
                }
                br {
                    display: none;
                }
            }
        }
        &.confirm-page {
            .content-note {
                font-weight: normal !important;
                text-align: center;
            }
        }
    }
    .register-extra-page {
        .title-head {
            font-size: 1.125rem;
            margin-top: 2.5rem !important;
            margin-bottom: 2rem !important;
        }
        .head-note {
            margin: 0 auto;
            font-size: 0.875rem;
        }
        .form-register-extra {
            .form-group {
                .input-group {
                    .form-control.select-dexpo {
                        width: 100%;
                    }
                    .label {
                        .mt-md-n5 {
                            margin-bottom: 0 !important;
                        }
                        .badge {
                            font-size: 0.75rem;
                        }
                    }
                    .mb-custom {
                        margin-bottom: .5rem;
                    }
                }
                &.input-choice {
                    padding-top: 2rem;
                    .head-group {
                        .text-center {
                            text-align: left !important;
                        }
                        .row.justify-content-between {
                            div {
                                padding: 0;
                                width: fit-content;
                                display: inline-block;
                                position: initial;
                            }
                        }
                        .badge {
                            position: absolute;
                            bottom: 100%;
                            right: 15px;
                            margin-bottom: 10px;
                        }
                    }

                  .option-group {
                    div[class^="col-"] {
                      padding-left: 15px;
                      padding-right: 15px;
                    }
                  }
                }
            }
        }
        
        .term {
            .term-head {
                br {
                    display: none;
                }
            }
            .term-list {
                margin-top: 1rem;
            }
        }

        .pc-desktop {
            display: none;
        }
        .sm-mobile {
            display: inline-block;
        }
    }
    .complete-page {
        .content-note {
            font-weight: normal !important;
            text-align: center;
            .datetime {
                strong {
                    font-weight: normal;
                    letter-spacing: 0px;
                    font-size: 1em;
                }
            }
        }
    }
    .term-box {
        .term-title {
            font-size: 1.1rem;
        }
      }
}

.text-underline {
    text-decoration: underline;
}
